export default [
  {
    header: "دسترسی ها",
  },
  {
    title: "زبان ها",
    icon: "FlagIcon",
    children: [
      {
        title: "همه زبان ها",
        route: "pages-languages-list",
      },
      {
        title: "افزودن زبان",
        route: "pages-languages-create",
      },
    ],
  },
  {
    title: "بلاگ ها",
    icon: "BookOpenIcon",
    children: [
      {
        title: "همه بلاگ ها",
        route: "pages-posts-list",
      },
      {
        title: "افزودن بلاگ",
        route: "pages-posts-create",
      },
      {
        title: "دسته ها",
        route: "pages-posts-category",
      },
    ],
  },
  {
    title: "رسانه ها",
    icon: "ImageIcon",
    children: [
      {
        title: "کتابخانه",
        route: "pages-library-list",
      },
      {
        title: "افزودن",
        route: "pages-library-add",
      },
    ],
  },
  {
    title: "برگه ها",
    icon: "ColumnsIcon",
    children: [
      {
        title: "همه برگه ها",
        route: "pages-all-pages",
      },
      {
        title: "افزودن برگه",
        route: "pages-create-page",
      },
    ],
  },
  {
    title: "دیدگاه ها",
    icon: "InfoIcon",
    route: "pages-comments-list",
  },
  {
    title: "فرم تماس",
    icon: "FileTextIcon",
    children: [
      {
        title: "صندوق ورودی",
        route: "pages-forms-inbox",
      },
      {
        title: "فرم های تماس و آنلاین",
        route: "pages-forms-list",
      },
      {
        title: "افزودن فرم جدید",
        route: "pages-forms-create",
      },
    ],
  },
  {
    title: "نمایش فهرست ها",
    icon: "EyeIcon",
    children: [
      {
        title: "لیست فهرست ها",
        route: "pages-menu-list",
      },
      {
        title: "افزودن فهرست",
        route: "pages-menu-create",
      },
    ],
  },
  {
    title: "سئو",
    icon: "SearchIcon",
    children: [
      {
        title: "سئوی برگه ها",
        route: "pages-seo-pages",
      },
      {
        title: "سئوی بلاگ ها",
        route: "pages-seo-blogs",
      },
      {
        title: "سئوی دسته بندی بلاگ",
        route: "pages-seo-blog-categories",
      },
    ],
  },
  {
    title: "کاربران",
    icon: "UsersIcon",
    children: [
      {
        title: "همه کاربران",
        route: "apps-users-list",
      },
    ],
  },
  {
    title: "تنظیمات",
    icon: "SettingsIcon",
    children: [
      {
        title: "تنظیمات عمومی",
        route: "pages-settings-general",
      },

      // {
      //   title: "جوایز و افتخارات",
      //   route: "pages-settings-awards",
      // },
      // {
      //   title: "ویدیو های صفحه اصلی",
      //   route: "pages-settings-videos",
      // },
      {
        title: "ربات",
        route: "pages-settings-robots",
      },
      {
        title: "سایت مپ",
        route: "pages-settings-sitemap",
      },
    ],
  },
  {
    title: "صفحه اصلی",
    icon: "HomeIcon",
    children: [
      {
        title: "اسلایدر",
        route: "pages-settings-slider",
      },
      {
        title: "سکشن زیر اسلایدر صفحه اصلی",
        route: "pages-settings-externals",
      },
      {
        title: "خدمات صفحه اصلی",
        route: "pages-settings-services",
      },
      {
        title: "رویداد های صفحه اصلی",
        route: "pages-settings-events",
      },
    ],
  },
  {
    title: "نمایندگان",
    icon: "FlagIcon",
    children: [
      {
        title: "لیست نمایندگان",
        route: "pages-representative-list",
      },
      {
        title: "افزودن نماینده",
        route: "pages-representative-create",
      },
    ],
  },
  // {
  //   title: "چت",
  //   route: "apps-chats",
  //   icon: "MessageCircleIcon",
  //   resource: "Support",
  //   action: "Support",
  // },
  // {
  //   title: "تیکت",
  //   icon: "MessageSquareIcon",
  //   children: [
  //     {
  //       title: "لیست تیکت ها",
  //       route: "apps-allTickets",
  //     },
  //   ],
  // },

  // {
  //   title: "سفارشات",
  //   icon: "TrelloIcon",
  //   children: [
  //     {
  //       title: "لیست سفارشات",
  //       route: "apps-orders-list",
  //       resource: "Accountent",
  //       action: "Accountent",
  //     },
  //   ],
  // },
  // {
  //   title: "مالی",
  //   icon: "DollarSignIcon",
  //   children: [
  //     {
  //       title: "پرداخت ها",
  //       route: "apps-financial-payments-list",
  //       resource: "Accountent",
  //       action: "Accountent",
  //     },
  //   ],
  // },
  // {
  //   title: "بلاگ",
  //   icon: "BookOpenIcon",
  //   // resource: 'Blogger',
  //   // action: 'Blogger',
  //   children: [
  //     {
  //       title: "لیست بلاگ ها",
  //       route: "pages-blog-list",
  //       resource: "Blogger",
  //       action: "Blogger",
  //     },
  //     {
  //       title: "افزودن بلاگ",
  //       route: "pages-blog-create",
  //       resource: "Blogger",
  //       action: "Blogger",
  //     },
  //     {
  //       title: "دسته بندی های بلاگ",
  //       route: "pages-blog-categories-list",
  //       resource: "Blogger",
  //       action: "Blogger",
  //     },
  //     {
  //       title: "لیست نظرات بلاگ",
  //       route: "pages-blog-comments",
  //       resource: "Blogger",
  //       action: "Blogger",
  //     },
  //   ],
  // },
];
